@charset "UTF-8";
body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Outfit", sans-serif;
  background: #ffffff;
}
body .footerStyle {
  color: rgb(18, 17, 17);
  border-top: 3px solid #e35f24;
}
body .displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .displayColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
body .displayStart {
  display: flex;
  align-items: center;
  justify-content: start;
}
body .displayEnd {
  display: flex;
  align-items: center;
  justify-content: end;
}
body .displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body .logoImg {
  width: 180px;
  margin-top: 18px;
}
body .crypto {
  animation: rotating3 3s linear infinite;
}
@keyframes rotating3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
body .sliderBox {
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: #121212;
  border-top: 3px solid #e35f24;
  text-align: center;
}
body .sliderBox img {
  width: auto;
  max-width: 165px;
}
body .chartBox {
  position: relative;
  width: 160px !important;
  height: 100px !important;
  min-height: 100px !important;
}
body .ChartFileBox {
  position: relative;
  width: 160px !important;
  height: 80px !important;
  min-height: 80px !important;
}
body .MuiList-padding {
  padding: 8px;
}
body .gradientBox {
  padding: 1px;
  background: linear-gradient(
      279.31deg,
      #ec1f24 5.51%,
      rgba(144, 56, 255, 0) 11.86%
    ),
    linear-gradient(100.56deg, #ec1f24 6.09%, hsla(0, 0%, 100%, 0) 17.3%),
    linear-gradient(0deg, hsla(0, 0%, 100%, 0.03), hsla(0, 0%, 100%, 0.03));
  border-radius: 10px;
}
body * {
  scrollbar-width: 0px;
  scrollbar-width: none;
}
body ::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
body ::-moz-scrollbar {
  width: 0;
  background: transparent;
}
body ::-webkit-scrollbar-thumb {
  background: transparent;
}
body .textfiledicons .MuiIconButton-root {
  color: #ec1f24 !important;
  padding: 6px;
  font-size: 17px;
  border: 1.2px solid #c9c9c9;
  margin-left: -4px;
  border-radius: 8px;
  margin-right: 8px;
}
body .mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 50px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
}
body .tabActiveButtons {
  background: #ec1f24;
  border-radius: 50px;
  padding: 11px 23px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
}
body .tabButtons {
  border-radius: 50px;
  padding: 11px 23px;
  white-space: pre;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
body .supportedBox {
  background: #000;
  padding: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
body .supportedBox::before {
  content: " ";
  position: absolute;
  /* top: 0px; */
  width: 240px;
  height: 200%;
  animation: animate 10s linear infinite;
  background: rgba(253, 199, 99, 0.25);
}
body .borderSpaceTable .MuiTable-root {
  border-collapse: separate;
  border-spacing: 0px 10px;
}
body .borderSpaceTable .MuiTableRow-root {
  background: #f4f7ff;
  border-radius: 5px;
}
body .borderSpaceTable .MuiTableCell-root {
  padding: 20px 16px;
}
body .transactionTable .MuiTableCell-head {
  background: #ffffff;
  font-weight: 300 !important;
  color: #78819f !important;
}
body .transactionTable .MuiTableHead-root {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
body .transactionTable .MuiTableRow-root {
  vertical-align: middle;
  background: #fbfbfb;
}
/* body .react-tel-input .flag-dropdown {
  border-radius: 7px 0 0 7px !important;
  background-color: #ededed;
} */
body .react-tel-input .flag {
  border-radius: 100px;
}
body .react-tel-input .flag-dropdown.open {
  border-radius: 7px 0 0 7px !important;
}
body .react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 7px 0 0 7px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}
body .MuiButton-contained.Mui-disabled {
  /* color: rgba(255, 255, 255, 0.3019607843) !important; */
}
body .p2pTable .MuiTableCell-head {
  background: #ffffff;
  font-weight: 300 !important;
  color: #78819f !important;
  padding: 7px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
body .p2pTable .MuiTableHead-root {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
body .p2pTable .MuiTableRow-root {
  vertical-align: middle;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
body .MuiAutocomplete-paper {
  padding: 5px 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
body .slidercssBox .slick-dots li button:before {
  font-size: 50px;
  content: "─";
  color: #000;
}
body .slidercssBox .slick-dots li {
  width: 30px;
}
body .slidercssBox .slick-dots li.slick-active button:before {
  color: #ec1f24;
  opacity: 1;
}
body .slidercssBox .slick-dots {
  bottom: -70px;
}
body .css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}
body .css-13cymwt-control {
  border-radius: 5px !important;
  min-height: 46px !important;
  background-color: #f9f9f9 !important;
  border-color: #eceaea !important;
}
body .css-t3ipsp-control {
  min-height: 46px !important;
}
body .css-1jqq78o-placeholder {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
body .css-1dimb5e-singleValue {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
body .greenbutton {
  color: #ffffff !important;
  font-size: 14px !important;
  background: #4ed46c !important;
  font-style: normal !important;
  text-align: center !important;
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  border-radius: 100px !important;
  padding: 8px 30px !important;
}
body .greenbutton:hover {
  background-color: #48e36a;
}
body .MuiOutlinedInput .MuiOutlinedInput-root {
  background: #ffffff;
  border-radius: 50px;
  color: unset;
}
body .whiteSpace {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
body .wordBreak {
  word-break: break-all;
}
body .css-1nmdiq5-menu {
  font-size: 12px;
}

body .blackButton {
  color: #fff;
  border: 1.5px solid rgba(255, 255, 255, 0.6);
  height: 52px;
  padding: 19px 30px;
  font-size: 20px;
  background: #080515;
  font-weight: 400;
  border-radius: 50px !important;
}
body .MuiFormHelperText-contained {
  margin-left: 0px !important;
}

* {
  scrollbar-width: 0px;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-moz-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

@media (max-width: 1024px) {
  .transactionTable {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
}
@media (max-width: 991px) {
  .transactionTable {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
}
@media (max-width: 767px) {
  .transactionTable {
    display: none;
  }
  .orangeshadeBox {
    display: none;
  }
  .purpleBox {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
} /*# sourceMappingURL=main.css.map */

/* .................................. Project Common Styling ............................................... */

/* ........................... MUI Related Styling .................................. */

.MuiSvgIcon-root.MuiSelect-iconOutlined {
  color: #1f9874 !important;
  /* height: 10px; */
}

/* ........................... Table Related Screen Styling .........................  */

.category-container {
  /* border: 2px solid red; */
  /* padding-top: 20px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mainHeadingBox {
  /* border: 2px solid rgb(67, 196, 32); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
}

@media (max-width: 800px) {
  .mainHeadingBox {
    flex-direction: column;
    gap: 10px;
  }
}

/* .filter-container {
  border: 2px solid rgb(0, 255, 89);

} */

.button-container {
  /* border: 2px solid rgb(255, 0, 0); */

  display: flex;
  justify-content: end;
}

.button-sub-container {
  /* border: 2px solid rgb(0, 255, 98); */

  display: flex;
  /* padding: 5px; */
  /* width: 50%; */

  gap: 5%;
}

@media (max-width: 800px) {
  .button-sub-container {
    flex-direction: column;
    gap: 10px;
  }
}

/* .table-container {
  border: 2px solid rgb(148, 148, 148);

} */

.row-button-container {
  /* border: 2px solid rgb(136, 255, 0); */

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}

/* .row-button {
  border: 2px solid rgb(27, 131, 131);

  color: rgb(225, 140, 168);
  border-color: rgb(225, 140, 168);
} */

/* ........................ Add Edit View Screen Styling ........................ */

.add-edit-view-mainHeadingBox {
  /* border: 2px solid rgb(67, 196, 32); */
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1%;
}

.add-edit-view-container {
  /* border: 2px solid blue; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-edit-view-content-section {
  /* border: 2px solid red; */
  /* padding: 20px 2%; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-edit-view-content {
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.view-details-section1 {
  /* border: 2px solid pink; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.view-section1-person-img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.view-section1-content {
  /* border: 2px solid rgb(0, 0, 0); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
}

@media (max-width: 800px) {
  .view-section1-content {
    flex-direction: column;
    gap: 10px;
  }
}
.view-section1-person-details {
  /* border: 2px solid rgb(136, 26, 176); */
  display: flex;
  align-items: start;
  gap: 4%;
  width: 100%;
}

@media (max-width: 800px) {
  .view-section1-person-details {
    flex-direction: column;
    gap: 10px;
  }
}

.view-section1-person-info-container {
  /* border: 2px solid rgb(176, 26, 26); */
  display: flex;
  width: 100%;
}

.view-section1-person-info-new-container {
  background-color: #f0f0f0;
  padding: 10px 0 10px 10px;
}

.view-section1-person-info-content {
  /* border: 2px solid rgb(26, 148, 176);  */
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.view-section1-person-info {
  /* border: 2px solid rgb(26, 121, 176); */
  display: flex;
  gap: 2%;
}

.view-section1-btn-container {
  /* border: 2px solid rgb(26, 176, 41); */
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 4%;
}

@media (max-width: 800px) {
  .view-section1-btn-container {
    /* flex-direction: column;
    gap: 10px; */
    justify-content: center;
  }
}

.view-details-section2 {
  /* border: 2px solid rgb(54, 154, 45); */
  display: flex;
}

/* .view-details-section2-container {
  border: 2px solid rgb(176, 26, 98);
} */

/* @media (max-width: 700px) {
  .view-details-section2-container {
    flex-direction: column;
  }
}

@media (max-width: 1200px) and (min-width: 700px) {
  .view-details-section2-container {
    flex-direction: column;
  }
} */

.cardBox {
  /* border: 2px solid pink; */
  margin: 10px 0px;
  min-width: 31.5%;
}

/* .view-details-section3 {
  border: 2px solid rgb(123, 41, 205);
} */

/* .view-details-section3-container {
  border: 2px solid rgb(41, 201, 9);
} */

.view-table-cell {
  border: 1px solid rgb(173, 173, 173);
  cursor: pointer;
  text-align: center !important;

  &:hover {
    border: none;
  }

  &:focus {
    border: none;
  }
}

.view-tableContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.view-with-backBtn {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 1%;
  width: 100%;
}

.view-job-details-container {
  /* border: 2px solid rgb(0, 4, 128); */
  display: flex;
  gap: 2%;
  width: 50%;
}

.view-job-details {
  /* border: 2px solid rgb(238, 255, 0); */
  display: flex;
  align-items: start;
  gap: 1%;
  width: 100%;
}

.view-job-description-container {
  /* border: 2px solid red; */
  display: flex;
  gap: 2%;
}

@media (max-width: 900px) {
  .view-job-description-container {
    flex-direction: column;
    gap: 20px;
  }
}

.job-desc-section1,
.job-desc-section2 {
  /* border: 2px solid rgb(0, 51, 255); */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.job-desc-section1 {
  gap: 10px;
}

.view-job-location {
  /* border: 2px solid rgb(255, 170, 0); */
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 98%;
}

/* ............... Add Edit Style ................. */

.add-edit-content-section {
  /* border: 1px solid rgb(159, 194, 23); */
  /* padding: 0 20%; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* @media (max-width: 700px) {
  .add-edit-content-section {
    padding: 0;
  }
} */

.add-edit-content {
  /* border: 2px solid blue; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-edit-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-edit-button-section {
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
}

/* .................. Static Content Styling ...................... */

.StaticScreenContainer {
  /* border: 2px solid blue; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #78819f;
  border-radius: 8px;
  padding: 10px;
}

.StaticScreenTitle
  .MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #080515 !important;
  font-family: "Poppins", sans-serif !important;
}

.StaticScreenContent .css-ahj2mt-MuiTypography-root {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #080515 !important;
  font-family: "Poppins", sans-serif !important;
}

.StaticEditorContainer {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.StaticBtnContainer {
  /* border: 2px solid red; */
  /* padding: 0 25%; */
  display: flex;
  /* gap: 2%; */
  justify-content: center;
}

.ck-editor__editable_inline {
  min-height: 100px;
  color: #333333;
  font-family: "Outfit";
}

.StaticScreenbtn {
  /* border: 2px solid red; */
  padding-left: 80%;
}

.StaticScreenContent {
  /* border: 2px solid skyblue; */
  display: Flex;
  flex-direction: column;
  gap: 20px;
}
